<template>
  <component :is="clientData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="clientData === undefined"
    >
      <h4 class="alert-heading">
       Cliente no encontrado.
      </h4>
      <div class="alert-body">
        No se encontró ningún cliente con este ID. Consulte la lista de clientes para ver si hay otros clientes.
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>

    </b-alert>

    <b-tabs
      v-if="clientData"
      pills
    >
      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Cuenta</span>
        </template>
        <cliente-edit-tab-account
          :client-data="clientData"
          class="mt-2 pt-75"
        />
      </b-tab>
      <!-- Tab: Social -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="Share2Icon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Integracion Timbrado</span>
        </template>
        <cliente-edit-tab-social class="mt-2 pt-75" :client-data="clientData" />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import clienteStoreModule from '../clienteStoreModule'
import ClienteEditTabAccount from './ClienteEditTabAccount.vue'
import ClienteEditTabInformation from './ClienteEditTabInformation.vue'
import ClienteEditTabSocial from './ClienteEditTabSocial.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    ClienteEditTabAccount,
    ClienteEditTabInformation,
    ClienteEditTabSocial,
  },
  setup() {
    const userData = ref(null)
    const clientData= ref(null)
    const USER_APP_STORE_MODULE_NAME = 'app-clientes'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, clienteStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-clientes/fetchClient', { id: router.currentRoute.params.id })
      .then(response => { clientData.value = response.data })
      .catch(error => {
        if (error.response.status === 404) {
          clientData.value = undefined
        }
      })
    return {
      userData,
      clientData
    }

  },
}
</script>

<style>

</style>
