<template>

  <b-form>
    <b-row>
      <credential-plan-card :client-data="clientData"/>
      <user-client-info :client-data="clientData" :user-data="userData"/>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import CredentialPlanCard from './CredentialPlanCard.vue'
import UserClientInfo from './UserClientInfo.vue'
import store from '@/store'
import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend,CredentialPlanCard,
    UserClientInfo
  }, 
  props: {
    clientData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const userData=ref({})
    store.dispatch('app-clientes/fetchUserByEmail',props.clientData.email).then(res=>{
    userData.value=res;
    }).catch(err=>{
      console.log(err);
    })

    return {
    userData
    }
  },
}
</script>

<style>

</style>
