import axios from '@axios'
import { API_URL } from '../../../conf/environment'
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchClientes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${API_URL}clientes`, { params: queryParams })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    addCliente(ctx, clientData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${API_URL}clientes`, clientData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchClient(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${API_URL}clientes/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },  
    updateClient(ctx,clientData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${API_URL}clientes/${clientData.id}`,clientData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getCredential(ctx, id){
      return new Promise((resolve, reject) => {
        axios
          .get(`${API_URL}integrador/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    } , 
    fetchUserByEmail(ctx, email) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${API_URL}usuarios/byemail`,{email})
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
  },
}
