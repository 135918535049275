import { render, staticRenderFns } from "./ClienteEditTabSocial.vue?vue&type=template&id=42aca0f4&"
import script from "./ClienteEditTabSocial.vue?vue&type=script&lang=js&"
export * from "./ClienteEditTabSocial.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports