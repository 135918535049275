<template>
  <b-card
    no-body
    class="border-primary ml-2"
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
        Credenciales de dashboard
      </h5>
    </b-card-header>
    <b-card-body >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Username:</span>
            </th>
            <td class="pb-50">
              {{ userData.nombreUsuario }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Rol:</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.rol }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Email:</span>
            </th>
            <td class="pb-50">
              {{ userData.correoElectronico }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
             <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Estatus:</span>
            </th>
            <td class="pb-50">
                <b-form-radio
                    v-model="userData.estatus_id"
                    name="estatus-user"
                    value="1"
                    class="custom-control-primary"
                >Activo
                </b-form-radio>
                <b-form-radio
                    v-model="userData.estatus_id"
                    name="estatus-user"
                    value="2"
                    class="custom-control-primary"
                >Inactivo
                </b-form-radio>
            </td>
          </tr>

          <tr>
            <td colspan="2">
                
              
          
            </td>
          </tr>
        </table>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BCardHeader, BCardBody,BFormRadio,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import useClientesList from '../clientes-list/useClientesList'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar,BCardHeader, BCardBody,BFormRadio,
  },
  props: {
    clientData: {
      type: Object,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { resolveUserRoleVariant } = useClientesList()
    return {
      avatarText,
      resolveUserRoleVariant,
    }
  },
}
</script>

<style>

</style>
