import { render, staticRenderFns } from "./ClienteEditTabAccount.vue?vue&type=template&id=c5c54208&"
import script from "./ClienteEditTabAccount.vue?vue&type=script&lang=js&"
export * from "./ClienteEditTabAccount.vue?vue&type=script&lang=js&"
import style0 from "./ClienteEditTabAccount.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports