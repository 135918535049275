<template>
  <b-card
    no-body
    class="border-primary"
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
        Credenciales de timbrado
      </h5>
      <b-badge variant="light-primary">
        Produccion
      </b-badge>
      <small class="text-muted w-100">July 22, 2021</small>
    </b-card-header>
<!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="!credential"
    >
      <h4 class="alert-heading">
       No hay credenciales.
      </h4>
    </b-alert>
    <b-card-body v-if="credential" pills>
      <ul class="list-unstyled my-1">
        <li>
          <span class="align-middle">User:{{credential.rfc}}</span>
        </li>
        <li class="my-25">
          <span class="align-middle">Token:{{credential.token}}</span>
        </li>
        <li class="my-25">
          <div class="demo-inline-spacing">
          <b-form-radio
            v-model="credential.estatus"
            name="estatus-credential"
            value="Activo"
            class="custom-control-primary"
          >Activo
          </b-form-radio>
          <b-form-radio
            v-model="credential.estatus"
            name="estatus-credential"
            value="Inactivo"
            class="custom-control-primary"
          >Inactivo
          </b-form-radio>
          </div>
        </li>
      </ul>
              <!-- User Stats -->
        <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="DollarSignIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{credential.timbreActivado}}k
              </h5>
              <small>Activados</small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="TrendingUpIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{credential.timbreActual}}k
              </h5>
              <small>Usados</small>
            </div>
          </div>
        </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BButton,
  BFormGroup,
  BFormRadioGroup,
  BCol,
  BFormRadio,
  BAlert ,
  BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
    BFormGroup,
    BFormRadioGroup,
    BCol,
    BFormRadio ,
    BAlert,
    BAvatar,
  },
  props:{
    clientData:{
      type:Object,
      required:true
    }
  },
  setup(props){
    const credential=ref(null)
    const toast = useToast()

   store.dispatch('app-clientes/getCredential',props.clientData.id).then(res=>{
    credential.value=res.data;
   }).catch(err=>{
     toast({
              component: ToastificationContent,
                  props: {
                    title: err.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
   })
     const estatusOption = [
      { text: 'Activo', value: 'Activo' },
      { text: 'Inactivo', value: 'Inactivo' },
    ]
return {
  credential,
  estatusOption
}
  }
  
}
</script>

<style>

</style>
